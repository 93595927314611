import React from 'react';
import PropTypes from 'prop-types';

function Tick(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.458"
      height="20.464"
      viewBox="0 0 19.458 20.464"
    >
      <g data-name="Group 21" transform="translate(384.383 406.795)">
        <path
          fill={props.colour ? props.colour : "#fff"}
          d="M-364.925-406.306q-3.62 4.04-6.787 8.359a102.387 102.387 0 00-5.822 8.862q-.2.335-.489.936a2.822 2.822 0 01-2.586 1.817 2.846 2.846 0 01-1.174-.21 2.271 2.271 0 01-.852-.727 5.278 5.278 0 01-.6-1.167q-.28-.72-.657-2.118a2.157 2.157 0 01-.056-.21 10.517 10.517 0 01-.433-1.943 1.839 1.839 0 01.93-1.46 3.158 3.158 0 011.838-.664.657.657 0 01.475.175 2.437 2.437 0 01.419.748q.139.35.35.95.475 1.342.8 1.342.279 0 2.208-3.061 1.845-2.921 3.173-4.906 1.858-2.781 2.83-4.019a12.284 12.284 0 011.754-1.88 5.031 5.031 0 011.762-.88 12.727 12.727 0 012.726-.433z"
          data-name="Path 15"
        />
      </g>
    </svg>
  );
}

Tick.propTypes = {
  colour: PropTypes.string
};

export default Tick;
