import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Container from '../components/global/container';
import GetInTouch from '../components/global/get-in-touch';
import Header from '../components/global/header';
import SEO from '../components/global/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import PortableText from '../components/portableText';
import Layout from '../containers/layout';
import { buildImageObj, mapEdgesToNodes } from '../lib/helpers';
import { imageUrlFor } from '../lib/image-url';
import Tick from '../components/global/elements/svg/tick';

export const query = graphql`
  query ServicesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      phone
      email
      address
      map
    }
    static: sanityPage(slug: { current: { eq: "services-page" } }) {
      _rawHeading
      _rawIntro
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      media {
        image {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        video {
          asset {
            _id
            url
          }
        }
        alt
      }
    }
    services: allSanityServices(sort: { fields: order }) {
      edges {
        node {
          _id
          order
          title
          _rawDescription
          bullets
          bgMedia {
            image {
              crop {
                _key
                _type
                top
                bottom
                left
                right
              }
              hotspot {
                _key
                _type
                x
                y
                height
                width
              }
              asset {
                _id
              }
            }
            video {
              asset {
                _id
                url
              }
            }
            alt
          }
          bgColour {
            hex
          }
          work {
            _key
            work {
              _key
              slug {
                current
              }
            }
          }
        }
      }
    }
  }
`;

const ServicesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const content = (data || {}).static;
  const media = content.media;
  const services = (data || {}).services ? mapEdgesToNodes(data.services) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const [refH, inViewH ] = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const [ref, inView ] = useInView({
    triggerOnce: true,
    threshold: 0.60
  });

  const [ref2, inView2 ] = useInView({
    triggerOnce: true,
    threshold: 0.60
  });

  const [ref3, inView3 ] = useInView({
    triggerOnce: true,
    threshold: 0.60
  });

  const vidRef = useRef(null);

  useEffect(() => {
    window.addEventListener('scroll', function() {
      const element = document.querySelector('.vid-trigger');
      const vid = document.querySelector('.creative--vid');
      if(element && vid) {
        var viewportOffset = element.getBoundingClientRect();
        var viewPoint = viewportOffset.top + 100;
  
        if (viewPoint <= window.innerHeight && !vid.classList.contains('played')) {
          vid.play();
          vid.addEventListener("ended", function() {
            vid.classList.add('played');
          });
        } else {
          vid.pause();
        }
        
      }
    });
  });

  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
        {content.seo && (
          <SEO
          title={content.seo.title !== null ? content.seo.title : false}
          description={content.seo.description !== null ? content.seo.description : false}
          keywords={content.seo.keywords !== null ? content.seo.keywords : false}
          image={content.seo.image !== null ? content.seo.image : false}
        />
        )}
        <Container>
          <section className="section site-wide">
            <div className={`hero hero--services ${inViewH ? 'in-view' : ''}`} ref={refH}>
              <div className="col copy">
                {content._rawHeading && <PortableText blocks={content._rawHeading} />}
                {content._rawIntro && <PortableText blocks={content._rawIntro} />}
                {services.map((service, index) => {
                if(service.title === "Strategy") {
                  return <div key={index} className="services--strategy">
                    <h2>{service.title}</h2>
                    {service._rawDescription && <PortableText blocks={service._rawDescription} />}
                    {service.bullets && (
                      <ul>
                        {service.bullets.map((bullet, index) => (
                          <li key={index}>
                            <Tick colour="#87C13F" />
                            {bullet}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>;
                }
              })}
              </div>
              <div className="col image">
                {media[0].image && media[0].image.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(media[0].image))
                      .url()}
                    alt={media[0].image.alt}
                  />
                )}

                {media[0].video && media[0].video.asset && (
                  <video className="hero__media" muted autoPlay loop playsInline ref={vidRef}>
                    <source src={media[0].video.asset.url} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>

          </section>

          {services.map((service, index) => {
            if(service.title === "Creative") {
              return <section className="section service--section -creative" style={{'backgroundColor': service.bgColour.hex}} key={index} ref={ref}>
                  <div className='image -full'>
                      {service.bgMedia.image && service.bgMedia.image.asset && (
                        <img
                          src={imageUrlFor(buildImageObj(service.bgMedia.image))
                            .url()}
                          alt={service.bgMedia.alt}
                        />
                      )}

                      {service.bgMedia.video && service.bgMedia.video.asset && (
                        <video className="hero__media creative--vid" muted playsInline ref={vidRef}>
                          <source src={service.bgMedia.video.asset.url} type="video/mp4" />
                        </video>
                      )}
                  </div>
                  <div className={`vid-trigger grid-column copy ${inView ? 'in-view' : ''}`}>
                      <h2>{service.title}</h2>
                      {service._rawDescription && <PortableText blocks={service._rawDescription} />}
                      {service.bullets && (
                        <ul>
                          {service.bullets.map((bullet, index) => (
                            <li key={index}>
                              <Tick colour="#87C13F" />
                              {bullet}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                </section>;
              }
          
            if(service.title === "Digital") {
              return <section className={`section service--section -digital ${inView2 ? 'in-view' : ''}`} key={index} ref={ref2}>
                  <div className='site-wide grid-container grid-container--half'>

                    <div className='grid-column copy'>
                      <h2>{service.title}</h2>
                      {service._rawDescription && <PortableText blocks={service._rawDescription} />}
                      {service.bullets && (
                        <ul>
                          {service.bullets.map((bullet, index) => (
                            <li key={index}>
                              <Tick colour="#87C13F" />
                              {bullet}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    <div className='grid-column image'>
                      <img
                        src={imageUrlFor(buildImageObj(service.bgMedia.image))
                          .auto('format')
                          .url()}
                      />
                    </div>

                  </div>
                </section>;
              }
          
            if(service.title === "Social Media & Content") {
              return <section className={`section service--section -social ${inView3 ? 'in-view' : ''}`} key={index} ref={ref3}>
                  <div className='site-wide grid-container grid-container--half'>

                  <div className="grid-column image">
                      {service.bgMedia.image && service.bgMedia.image.asset && (
                        <img
                          src={imageUrlFor(buildImageObj(service.bgMedia.image)).auto('format').url()}
                          alt={service.bgMedia.alt}
                        />
                      )}

                      {service.bgMedia.video && service.bgMedia.video.asset && (
                        <video autoPlay loop muted playsInline>
                          <source src={service.bgMedia.video.asset.url} type="video/mp4" />
                        </video>
                      )}
                    </div>

                    <div className='grid-column copy'>
                      <h2>{service.title}</h2>
                      {service._rawDescription && <PortableText blocks={service._rawDescription} />}
                      {service.bullets && (
                        <ul>
                          {service.bullets.map((bullet, index) => (
                            <li key={index}>
                              <Tick colour="#87C13F" />
                              {bullet}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                  </div>
                </section>;
              }
          })}

          <section
            className='team--photo'
            style={{'backgroundImage': `url(${imageUrlFor(buildImageObj(media[1].image))})`}}
          >

          </section>

        </Container>
      </Layout>
    </>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default ServicesPage;
